<template>
    <page title="校园公告" :hasBack="true" textAlign="left">
        <div class="school-notice-page">
            <van-list
                @load="onLoad"
                v-model:loading="loading"
                :finished="finished"
                finished-text="已经到底了"
                :offset="50"
                :immediate-check="false"
                v-if="!schoolNoticeHasLoad || schoolNoticeList.length"
            >
                <div class="item-wrapper">
                    <notice-item
                        v-for="item in schoolNoticeList"
                        :title="item.title"
                        :time="item.createTime"
                        :id="item.id"
                        :key="item.id"
                    />
                </div>
            </van-list>
            <empty v-else />
        </div>
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import NoticeItem from '@/components/noticeItem/NoticeItem.vue';
import Empty from '@/components/empty/Empty';
import { List } from 'vant';
import { ref } from 'vue';
import commonRequest from '@/services';
export default {
    name: 'schoolNotice',
    components: {
        [Page.name]: Page,
        [Empty.name]: Empty,
        [NoticeItem.name]: NoticeItem,
        [List.name]: List,
    },
    setup() {
        const schoolNoticeList = ref([
            // {
            //     content: '以节水、节电、节粮为荣，随手关紧水龙头手关紧水龙头手关紧水龙头',
            //     time: '2021-10-01 07:18:15',
            //     id: 1,
            // },
            // {
            //     content: '以节水、节电、节粮为荣，随手关紧水龙头手关紧水龙头手关紧水龙头',
            //     time: '2021-10-01 07:18:15',
            //     id: 2,
            // },
        ]);
        const schoolNoticeHasLoad = ref(false);
        const loading = ref(false);
        const finished = ref(false);
        const pageNum = ref(0);
        const initPage = 15;
        const onLoad = () => {
            pageNum.value++;
            commonRequest({ action: 11002, type: 1, pageNum: pageNum.value, pageSize: initPage }).then((res) => {
                schoolNoticeHasLoad.value = true;
                if (res.data && res.data.rows) {
                    loading.value = false;
                    schoolNoticeList.value = schoolNoticeList.value.concat(res.data.rows);
                    if (res.data.rows.length < initPage) {
                        finished.value = true;
                    }
                }
            });
        };
        onLoad();
        return {
            schoolNoticeList,
            schoolNoticeHasLoad,
            onLoad,
            loading,
            finished,
        };
    },
};
</script>
<style lang="less" scoped>
.school-notice-page {
    height: 100%;
    .container {
        height: 100%;
    }
    .item-wrapper {
        background: #ffffff;
        border-radius: 10px;
        margin-bottom: 10px;
        padding-left: 10px;
    }
}
</style>
